/* eslint-disable import/extensions */

import img1 from '../assets/images/Services/img1.jpeg';
import img2 from '../assets/images/Services/img2.jpeg';
import img3 from '../assets/images/Services/img3.jpeg';

import Communicative from '../assets/images/Advantages/Communicative.png';
import Collaborative from '../assets/images/Advantages/Collaborative.png';
import Management from '../assets/images/Advantages/Management.png';
import Favorite from '../assets/images/Advantages/Favorite.png';

import Sasha from '../assets/images/Testimonials/Sasha.jpg';
import Reiner from '../assets/images/Testimonials/Reiner.jpg';
import Kruger from '../assets/images/Testimonials/Kruger.jpg';

import Vodafone from '../assets/images/client/vodafone.png';
import Broad from '../assets/images/client/broad.jpg';
import Bajaj from '../assets/images/client/bajaj.jpg';
import Credit from '../assets/images/client/credit.jpg';
import Idbi from '../assets/images/client/idbi.png';
import National from '../assets/images/client/national.jpg';
import Birla from '../assets/images/client/birla.png';
import Kart from '../assets/images/client/kart.jpg';
// import Mobile1 from '../assets/images/client/Mobile1.jpg';
// import Mobile2 from '../assets/images/client/Mobile2.jpg';
// import UIUX1 from '../assets/images/client/UIUX1.jpg';
// import UIUX2 from '../assets/images/client/UIUX2.jpg';

export const Services = [
  {
    title: 'CourtClarity Recovery Services',
    imageUrl: img1,
    animation: 'left',
  },
  {
    title: 'ProCase Recovery',
    imageUrl: img2,
    animation: 'up',
  },
  {
    title: 'Resolution Agency',
    imageUrl: img3,
    animation: 'right',
  },
];

export const Portfolios = [
  {
    id: 'asd1293uasdads1',
    title: 'Tele Calling',
    imageUrl: "https://images.pexels.com/photos/8867434/pexels-photo-8867434.jpeg",
    responsibility: [
      'Mobile Development',
      'UI/UX Design',
    ],
  },
  {
    id: 'asd1293uhjkhkjh2',
    title: 'Credit Risk Management',
    imageUrl: "https://images.pexels.com/photos/19867471/pexels-photo-19867471/free-photo-of-credit-risk-management.jpeg",
    type: 'Mobile Apps',
    responsibility: [
      'Mobile Development',
      'UI/UX Design',
    ],
  },
  {
    id: 'asd1293uvbvcbbd3',
    title: 'Enter Price Risk & Advisory',
    imageUrl: "https://images.pexels.com/photos/8358129/pexels-photo-8358129.jpeg",
    type: 'Mobile Apps',
    responsibility: [
      'Mobile Development',
      'UI/UX Design',
    ],
  },
  {
    id: 'asd1293ufgdfgs4',
    title: 'Debt Collection Services',
    imageUrl: "https://images.pexels.com/photos/6770515/pexels-photo-6770515.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
    type: 'Website',
    responsibility: [
      'Web Development',
      'UI/UX Design',
    ],
  },
  {
    id: 'asd1293ulskmnb5',
    title: 'Data validation Services',
    imageUrl: "https://images.pexels.com/photos/6077354/pexels-photo-6077354.jpeg",
    type: 'Website',
    responsibility: [
      'Web Development',
      'UI/UX Design',
    ],
    
  },
  {
    id: 'asd1293ulkmnbj6',
    title: 'Field Visits',
    imageUrl: "https://images.pexels.com/photos/5990037/pexels-photo-5990037.jpeg?auto=compress&cs=tinysrgb&w=600",
    type: 'Website',
    responsibility: [
      'Web Development',
      'UI/UX Design',
    ],
  },
];

export const Advantages = [
  [{
    title: 'Experienced Legal Professionals',
    description: 'Our team of highly skilled lawyers brings years of expertise in various legal fields, ensuring you receive accurate advice and powerful representation.',
    imageUrl: Communicative,
  },
  {
    title: 'Personalized Legal Solutions',
    description: 'We understand that every case is unique. That’s why we tailor our strategies to meet your specific needs and goals.',
    imageUrl: Management,
  }],
  [{
    title: 'Proven Success Record​',
    description: 'With a track record of winning cases and achieving favorable outcomes, our reputation speaks for itself. Your success is our priority.',
    imageUrl: Collaborative,
  },
  {
    title: 'Trustworthy Service',
    description: "We believe in honesty and transparency. You can rely on us for clear communication, fair pricing, and ethical legal support.",
    imageUrl: Favorite,
  }],
];
export const Testimonials = [
  {
    id: 1,
    name: 'A. Sharma',
    testimoni: 'I could not be more satisfied with the professional legal support I received. The team guided me through every step, ensuring a favorable outcome. Highly recommend their services!',
    imageUrl: Sasha,
  },
  {
    id: 2,
    name: 'R. Patel',
    testimoni: 'Their transparent communication and attention to detail made a challenging situation much easier to handle. I felt confident knowing my case was in the best hands.',
    imageUrl: Kruger,
  },
  {
    id: 3,
    name: 'S. Verma',
    testimoni: 'They went above and beyond to resolve my legal issue. Their dedication and expertise were truly impressive. Thank you for your exceptional service!',
    imageUrl: Reiner,
  },
];

export const TeamMembers = [
  {
    name: 'Voda Fone',
    imageUrl: Vodafone,
  },
  {
    name: 'Connect Broadband',
    imageUrl: Broad,
  },
  {
    name: 'Bajaj Allianz',
    imageUrl: Bajaj,
  },
  {
    name: 'Home Credit',
    imageUrl: Credit,
  },
  {
    name: 'IDBI Bank',
    imageUrl: Idbi,
  },
  {
    name: 'National Insurance',
    imageUrl: National,
  },
  {
    name: 'Aditya Birla',
    imageUrl: Birla,
  },
  {
    name: 'LendingKart',
    imageUrl: Kart,
  },
];
